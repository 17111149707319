<template>
  <div class="wrapper">
    <side-bar v-if="!layout">
      <template slot="links">
        <sidebar-link
          to=""  
          :name="$t('sidebar.chatbot')"
          icon="tim-icons icon-chat-33"
          @click.native="openChatbot"
        />
        <sidebar-link
          to=""
          :name="$t('sidebar.spy')"
          icon="tim-icons icon-zoom-split"
          @click.native="openSpy"
        />
        <sidebar-link
          to="/tools/copy-generator"
          :name="$t('sidebar.iatools')"
          icon="tim-icons icon-atom"
        />
      </template>
    </side-bar>
    <div class="main-panel" v-if="!layout">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
    <dashboard-content
      @click.native="toggleSidebar"
      v-if="layout"
      :layout="layout"
    >
    </dashboard-content>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    openChatbot() {
      window.open("https://chatbot.geniustools.io", '_blank', 'noreferrer');
    },
    openSpy() {
      window.open("https://spy.geniustools.io", '_blank', 'noreferrer');
      // window.open("http://localhost:8080", '_blank', 'noreferrer');
    }
  },
  data() {
    return {
      layout: this.$route.meta.layout
    }
  }
};
</script>
